body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
    text-align: center;
    margin-top: 60px;
}
form {
    background-color: 'red';
}
.imgcontainer {
  text-align: center;
  margin: 24px 0 10px 0;
  margin-top: -4%;
}
img.avatar {
  width: 30%;
}
.container {
  padding: 25px;
}
.change_password{
  margin-top: 30;
  margin-left: '40%';
  color: black;
  font-size: 2vw;
  text-decoration: underline 1.3px;
}
input[type=text_ChangePswd], input[type=password_ChangePswd] {
  width: 40%;
  padding: 20px;
  margin: 8px 0;
  background-color: white;
  border: none;
  text-align: center;
  outline: none;
  font-size: 1.2vw;
}
input[type=text_ChangePswd], input[type=password_ChangePswd]::placeholder {
  text-align: center;
  font-size: 1.2vw;
}

label{
    color: black;
   
}
.update_btn_ChangePswd{
  background-color: #61777F;
  color:  white;
  padding: 12px 20px;
  margin: 30px 0;
  border: none;
  cursor: pointer;
  width: 20vw;
  border-radius: 50px;
  height: 3vw;
  font-size: 1.2vw;
}
.update_btn_ChangePswd:hover {
  opacity: 0.5;
}

/*Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    background-color: aqua;
  }
}

