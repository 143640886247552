.imgcontainer {
    text-align: center;
    /* margin-top: 2% ; */
    /* margin-left: 5.3%; */
    margin-top: -4%;
  }
  img.avatar {
    width: 30.8%;
  }
  body {
    font-family: "Lato", sans-serif;
    background-color: white;
  }

  .sidenav {
    height: 100%;
    width: 9.1%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #3f2718;
    overflow-x: hidden;
    text-align: center;
    border-radius: 0px 30px 30px 0px;
  }

  /*.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #e1d0bf;
    display: block;
  }*/

  img.home-icon{
      opacity: 0.8;
      height: 30px;
      width: 35px;
      margin-top: 100%;

  }
  img.database-icon{
    opacity: 0.7;
    height: 36px;
    width: 36px;
    margin-top: 20%;
}
  img.cloud-icon{
      opacity: 0.9;
      height: 40px;
      width: 38px;
      margin-top: 20%;
  }
  img.settings-icon{
      height: 33px;
      width: 35px;
      opacity: 1;
      margin-top: 20%;

  }
  img.logout-icon_Product{
      opacity: 0.9;
      margin-top: 200%;
      margin-bottom: 5%;
      height: 30px;
      width: 35px;
  }

  .main_hproduct {
    margin-left: 9.3%; /* Same as the width of the sidenav */
    font-size: 18px; /* Increased text to enable scrolling */
    padding: 0px 10px;

  }
  a
  {
      text-decoration: none;
      color: black;
      line-height: 2.5;

  }
  .hardwareCompanyName{
    margin-top: 0%;
    color: black;
    font-size: 1.3vw;
    float: left;
    margin-left: 1.5vw;
  }
  .plus_img{
    height: 1vw;
    margin-left: 3px;
  }
  .addBtn{
    margin-top: 0px;
    margin-bottom: 3%;
    background-color: #61777F;
    color: white;
    border-radius: 8vw;
    height: 2.5vw;
    width: 2.5vw;
    border: none;
  }
  .add_Btn_position{
    text-align: right;
  }



  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}

    /*.sidenav a {font-size: 18px;}*/
  }

  .p1_Card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 20px;
    margin-top: 6%;
    width: 20vw;
  }

  .p1_Card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
  }

  .logo1_pos{
    text-align: center;
    position: relative;
  }

  img.card1_companyLogo {
    border-radius: 10px 10px 10px 10px;
    margin-top: 10px;
    height: 150px;
    width: 150px;
  }
  input.inputName{
    text-decoration: underline;
    border: none;
    width: 10%;
    height: 5%;
  }
  .trashBtn{
    padding-right: 20px;
    border-radius: 200px;
    border: none;
    background-color: white;
    width: 5%;
    margin-left: 17vw;
    margin-top: 0.8vw;
  }
  .trashBtn:hover{
    background-color: transparent;
  }
  input[type=checkbox] {
    accent-color: #61777F;
    height: 18px;
    width: 19px;
    /* margin-left: -11%; */
    margin-top: 5%;
    /* margin-left: 50%; */
    background-color: rebeccapurple;
  }
 .checkbox_pos{
  /* margin-left: 0vw; */
  /* flex-direction: row; */
  /* align-self: center; */
  /* align-items: center; */
  /* margin-left: auto; */
  margin-bottom: 2%;
  }
  .card1Container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create three columns for the cards */
    gap: 30px; /* Add spacing between the cards */
    padding: 16px;
    width: 12vw;
    margin-top: -1%;
    float: left;
    margin-left: 0.8vw;
  }

.nextBtn_HardwareProductName{
  margin-top: 8%;
  /* margin-bottom: 10%; */
  background-color: #61777F;
  color: white;
  border-radius: 8vw;
  height: 3vw;
  width: 3vw;
  border: none;
  position: fixed;
  cursor: pointer;
  }
  .nextBtn_HardwareProductName:hover{
    background-color: #61777F;
  }

  .next_Btn_position_HardwareProductName{
  position: relative;
  width: 158vw;
  top: 40vh;
  overflow: visible;
  }

.quantity-counter {
  /* display: flex; */
  align-self: center;
  align-items: center;
  flex-direction: row;
}

.quantity-btn {
  background-color: #61777F;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.quantity-display {
  margin: 0 10px;
  font-size: 18px;
}



