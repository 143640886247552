.imgcontainer {
    text-align: center;
    /* margin-top: 2% ; */
    /* margin-left: 5.3%; */
    margin-top: -4%;
  }
  img.avatar {
    width: 30.8%;
  }
  body {
    font-family: "Lato", sans-serif;
    background-color: white;
  }
  
  .sidenav {
    height: 100%;
    width: 9.1%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #3f2718;
    overflow-x: hidden;
    text-align: center;
    border-radius: 0px 30px 30px 0px;
  }
  
  /*.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #e1d0bf;
    display: block;
  }*/
  
  img.home-icon{
      opacity: 0.8;
      height: 30px;
      width: 35px;
      margin-top: 100%;
      
  }
  img.database-icon{
    opacity: 0.7;
    height: 36px;
    width: 36px;
    margin-top: 20%;
}
  img.cloud-icon{
      opacity: 0.9;
      height: 40px;
      width: 38px;
      margin-top: 20%;
  }
  img.settings-icon{
      height: 33px;
      width: 35px;
      opacity: 1;
      margin-top: 20%;
  
  }
  img.logout-icon{
      opacity: 0.9;
      margin-top: 200%;
      height: 30px;
      width: 35px;
  }
  
  .main_companies {
    margin-top: 4%;
    margin-left: 9.3%; /* Same as the width of the sidenav */
    font-size: 18px; /* Increased text to enable scrolling */
    padding: 0px 10px;
    
  }
  a
  {
      text-decoration: none;
      color: #3f2718;
      line-height: 2.5;
      
  }
  .hardwareCompanies{
    margin-top: 0%;
    color: #3f2718;
    font-size: 1.3vw;
    float: left;
    margin-left: 1.5vw;

  }
  .plus_img{
    height: 1vw;
    margin-left: 3px;
  }
  .addBtn{
    margin-top: 1vw;
    margin-bottom: 3%;
    background-color: #61777F;
    color: white;
    border-radius: 8vw;
    height: 2.5vw;
    width: 2.5vw;
    border: none;
  }
  .add_Btn_position{
    text-align: right;
  }
  
  
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    
    /*.sidenav a {font-size: 18px;}*/
  }
.company1, .company2, .company3, .company4, .company5, .company6{
  border-radius: 20px;
}
.card1{
  position: absolute;
  margin-left:0px;
  margin-top: 50px;
}
.card2{
  position: absolute;
  margin-left:0px;
  margin-top: 350px;

}
.card3 {
  position: absolute;
  margin-left:380px;
  margin-top: 50px;
}
.card4{
  position: absolute;
  margin-left:380px;
  margin-top: 350px;
}
.card5{
  position: absolute;
  margin-left:800px;
  margin-top: 50px;
}
.card6{
  position: absolute;
  margin-left:800px;
  margin-top: 350px;
}
.nextBtn{
  margin-top: 5%;
  margin-bottom: 10%;
  background-color: #3f2718;
  color: #e1d0bf;
  border-radius: 20px;
  height: 30px;
  width: 70px;
}
.next_Btn_position{
  text-align: right;
}

.company_card1Container_HardwareCompanies {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create three columns for the cards */
  gap: 2vw; /* Add spacing between the cards */
  padding: 16px;
  margin-top: -3vw;
  float: left;
  margin-left: 1vw;
} 
.innerCard{
  width: 100%;
}
.company_logo1_pos{
  text-align: center;
  position: relative;
}

img.comapny_card1_companyLogo {
  border-radius: 10px 10px 10px 10px;
  margin-top: 10px;
  height: 150px;
  width: 150px;
}

.p1_Card_HardwareCompanies {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 20px;
  margin-top: 6%;
  width: 20vw;
}

.p1_Card_HardwareCompanies:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
}