body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #e1d0bf;
    text-align: center;
    margin-top: 60px;
}
form {
    background-color: 'red';
}
.imgcontainer {
  text-align: center;
  margin: 24px 0 10px 0;
  margin-top: -4%;
}
img.avatar {
  width: 30%;
}
.container {
  padding: 25px;
}
.forgot_password{
  margin-top: 30;
  margin-left: '40%';
  color: #3f2718;
  font-size: 2vw;
  text-decoration: underline 1.3px;
}
input[type=text_ForgotPswd], input[type=password_ForgotPswd] {
  width: 30%;
  padding: 10px;
  margin: 0px 0;
  background-color: white;
  border: none;
  text-align: center;
  font-size: 1.2vw;
  outline: none;
}
input[type=text_ForgotPswd], input[type=password_ForgotPswd]::placeholder {
  text-align: center;
  font-size: 1.2vw;
}
label{
    color: #3f2718;
   
}
.signIn_btn_ForgotPswd{
  background-color: #3f2718;
  color:  white;
  padding: 12px 20px;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  width: 20%;
  border-radius: 50px;
  height: 3vw;
  font-size: 1.2vw;
}
.signIn_btn_ForgotPswd:hover {
  opacity: 0.5;
}
.sendOTP_btn_ForgotPswd{
  background-color: #3f2718;
  color:  white;
  padding: 12px 20px;
  margin: 30px 0;
  border: none;
  cursor: pointer;
  width: 15%;
  border-radius: 50px;
  height: 3vw;
  font-size: 1.2vw;

}
.sendOTP_btn_ForgotPswd:hover{
  background-color: #3f2718;
}

/*Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    background-color: aqua;
  }
}

