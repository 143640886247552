.imgcontainer {
  text-align: center;
  /* margin-top: 2% ; */
  /* margin-left: 4%; */
  margin-top: -4%;
}
img.avatar {
  width: 30%;
}
body {
  font-family: "Lato", sans-serif;
  background-color: white;
}
.addCompanyName{
  margin-top: 0%;
  float: left;
  margin-left: 0vw;
  color: #3f2718;
  font-size: 16px;
  text-align: left;
}
.sidenav {
  height: 100%;
  width: 9%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #3f2718;
  overflow-x: hidden;
  text-align: center;
  border-radius: 0px 30px 30px 0px;
}

/*.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #e1d0bf;
  display: block;
}*/

img.home-icon{
    opacity: 0.8;
    height: 30px;
    width: 35px;
    margin-top: 100%;
    
}
img.database-icon{
  opacity: 0.7;
  height: 36px;
  width: 36px;
  margin-top: 20%;
}
img.cloud-icon{
    opacity: 0.9;
    height: 40px;
    width: 38px;
    margin-top: 20%;
}
img.settings-icon{
    height: 33px;
    width: 35px;
    opacity: 1;
    margin-top: 20%;

}
img.logout-icon{
    opacity: 0.9;
    margin-top: 200%;
    height: 30px;
    width: 35px;
}

.main {
  margin-top: 0%;
  margin-left: 14%; /* Same as the width of the sidenav */
  font-size: 18px; /* Increased text to enable scrolling */
  padding: 0px 10px;
  
}
a
{
    text-decoration: none;
    color: #3f2718;
    line-height: 2.5;
}

.column1_addHardwareProduct {
  float: left;
  width: 20%;
  /* background-color: red; */
  text-align: left;
}
.column2_addHardwareProduct {
  float: left;
  width: 40%;
  /* background-color: yellowgreen; */
  text-align: left;

}

/* Clear floats after the columns */
.row_addHardwareProduct:after {
  content: "";
  display: table;
  clear: both;
}

.enterProductCode_addHardwareProduct{
  text-align: left;
  color: #3f2718;
  font-size: 1.4vw;
}



.enterName{
  /* margin-right:87.5%; */
  color: #3f2718;
  font-size: 1.4vw;
  text-align: left;
}

.UploadImage{
  font-size: 1.5vw;
  /* margin-right:89%; */
  color: #3f2718;
  text-align: left;

}
.enterProductCode_input, .enterName_input, .enterColorCode_input{
  color: #3f2718;
  width: 20%;
  margin-right: 80%;
  border-color: #3f2718;
  border-radius: 20px;
  /* background-color: #e1d0bf; */
  height: 28px;
  outline: none;
  display: inline-block;
  font-size: 1.2vw;
  padding-left: 1%;
}

.upload_img{
  height: 50px;
  margin-right: 88%;
  /* margin-top: 2%; */
}

.nextBtn{
margin-top: 5%;
margin-bottom: 10%;
background-color: #3f2718;
color: #e1d0bf;
border-radius: 20px;
height: 30px;
width: 70px;
}
.nextBtn:hover{
background-color: #3f2718;
}



.next_Btn_position_addProduct{
float: right;
margin-top: 50px;
margin-bottom: 20px;
}





@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  /*.sidenav a {font-size: 18px;}*/
}