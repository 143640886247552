  .main_kitchen {
    /* margin-top: -7%; */
    margin-left: 17%; /* Same as the width of the sidenav */
    font-size: 18px; /* Increased text to enable scrolling */
    padding: 0px 10px;
    
  }
  .typeOfKitchen{
    color: black;
    float: left;
  }
  .gridContainer{
    margin-top: 2%;
  }
  a
  {
      text-decoration: none;
      color: black;
      line-height: 2.5;
      
  }
  .g_btn, .straight_btn, .u_btn, .island_btn, .l_btn, .parallel_btn{
    background-color: white;
    color:  black;
    padding: 12px 20px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 50px;
    font-size: 1vw; 
  }
  .g_btn:hover, .straight_btn:hover, .u_btn:hover, .island_btn:hover, .l_btn:hover, .parallel_btn:hover{
    background-color: #61777F;
    color: white;
  }
.card1_KitchenTypes{
  position: absolute;
  margin-left:0px;
  margin-top: 1%;
}
.card2_KitchenTypes{
  position: absolute;
  margin-left:0px;
  margin-top: 20vw;

}
.card3_KitchenTypes {
  position: absolute;
  margin-left:60vh;
  margin-top: 1%;
}
.card4_KitchenTypes{
  position: absolute;
  margin-left:60vh;
  margin-top: 20vw;
}
.card5_KitchenTypes{
  position: absolute;
  margin-left:120vh;
  margin-top: 1%;
}
.card6_KitchenTypes{
  position: absolute;
  margin-left:120vh;
  margin-top: 20vw;
}
.nextBtn{
  margin-top: 5%;
  margin-bottom: 10%;
  background-color: black;
  color: #e1d0bf;
  border-radius: 20px;
  height: 30px;
  width: 70px;
}
.nextBtn:hover{
  background-color: #3f2718;
}

.card1-img, .card2-img, .card3-img, .card4-img, .card5-img, .card6-img{
  border-radius: 20px;
  height: 12vw;
}

@media screen and (min-width: 2000px) {
  .card1-img, .card2-img, .card3-img, .card4-img, .card5-img, .card6-img{

    border-radius: 20px;
  }
}




  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    /*.sidenav a {font-size: 18px;}*/
  }