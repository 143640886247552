.imgcontainer {
    text-align: center;
    /* margin-top: 2% ; */
    /* margin-left: 4%; */
    margin-top: -4%;
  }
  img.avatar {
    width: 30%;
  }
  body {
    font-family: "Lato", sans-serif;
    background-color: white;
  }

  input.inputHeight,input.inputWidth[type=text]{
    flex: 1;
    width: 20%;
    padding: 10px;
    margin-top: -10px;
    margin-left: 4%;
    background-color: white;
    border: 3px solid ;
    text-align: center;
    color: black;  
    border-radius: 30px;
    opacity: 0.9;
    outline: transparent;
  }
  
  input.inputHeight[type=text]::placeholder {
    color: black;
    text-align: center;
    font-size: 14px;
    opacity: 0.4;
  }
  input.inputWidth[type=text]::placeholder {
    color: black;
    text-align: center;
    font-size: 14px;
    opacity: 0.4;
  }

 .heightxWidth_1{
    color: black;
    margin-right: 8vw;
    flex-direction: row;
    font-size: 1.3vw;
  }
  .heightxWidth_2{
    color: black;
    margin-right: 8vw;
    flex-direction: row;
    font-size: 1.3vw;
  }
  .heightxWidth_3{
    color: black;
    margin-right: 6.5vw;
    flex-direction: row;
    font-size: 1.3vw;
  }
  .heightxWidth_4{
    color: black;
    margin-right: 5vw;
    flex-direction: row;
    font-size: 1.3vw;
  }



  .enterKitchenDimensions{
    color: black;
    margin-right: 70%;
    font-size: 1.4vw;
  }
  
  img.home-icon{
      opacity: 0.8;
      height: 30px;
      width: 35px;
      margin-top: 100%;
      
  }
  img.database-icon{
    opacity: 0.7;
    height: 36px;
    width: 36px;
    margin-top: 20%;
}
  img.cloud-icon{
      opacity: 0.9;
      height: 40px;
      width: 38px;
      margin-top: 20%;
  }
  img.settings-icon{
      height: 33px;
      width: 35px;
      opacity: 1;
      margin-top: 20%;
  
  }
  img.logout-icon{
      opacity: 0.9;
      margin-top: 200%;
      height: 30px;
      width: 35px;
  }
  
  .main_kitchenDimension {
    margin-top: 4%;
    margin-left: 12%; /* Same as the width of the sidenav */
    font-size: 18px; /* Increased text to enable scrolling */
    padding: 0px 10px;
  
  }
  a
  {
      text-decoration: none;
      color: black;
      line-height: 2.5;
      
  }
  .nextBtn_KitchenDimensions{
    margin-top: 5%;
    margin-bottom: 10%;
    background-color: #61777F;
    color: #e1d0bf;
    border-radius: 8vw;
    height: 3vw;
    width: 3vw;
    border: none;
    }
    .nextBtn_KitchenDimensions:hover{
      background-color: black;
    }
     
    .next_Btn_position_KitchenDimensions{
    float: right;
    margin-top: 18vh;
    /* margin-bottom: 20px; */
    }

    .img_rightArrow{
      height: 2vh;
    }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    /*.sidenav a {font-size: 18px;}*/
  }