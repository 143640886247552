.imgcontainer {
    text-align: center;
    margin-top: 2% ;
    margin-left: 4%;
  }
  img.avatar {
    width: 30%;
  }
  body1 {
    font-family: "Lato", sans-serif;
    background-color: white;
  }
  
  .sidenav {
    height: 100%;
    width: 9%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #3f2718;
    overflow-x: hidden;
    text-align: center;
    border-radius: 0px 30px 30px 0px;
  }
  
  /*.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #e1d0bf;
    display: block;
  }*/
  
  img.home-icon{
      opacity: 0.8;
      height: 30px;
      width: 35px;
      margin-top: 100%;
      
  }
  img.database-icon{
    opacity: 0.7;
    height: 36px;
    width: 36px;
    margin-top: 20%;
}
  img.cloud-icon{
      opacity: 0.9;
      height: 40px;
      width: 38px;
      margin-top: 20%;
  }
  img.settings-icon{
      height: 33px;
      width: 35px;
      opacity: 1;
      margin-top: 20%;
  
  }
  img.logout-icon{
      opacity: 0.9;
      margin-top: 200%;
      height: 30px;
      width: 35px;
  }
  
  .main_ProductInfo {
    /* margin-top: 2%; */
    margin-left: 14%; /* Same as the width of the sidenav */
    font-size: 18px; /* Increased text to enable scrolling */
    padding: 0px 10px;
    
  }
  a
  {
    text-decoration: none;
    color: black;
    line-height: 2.5;
      
  }

  .searchBar_pos_productInfo{
  
    border-radius: 60px;
    border:solid;
    border-color: #61777F;
    margin-left: 85%;
    height: 30px;
    width: 10vw;
    align-items: center;
    opacity: 0.9;
  
  }
  .search_icon{
    height: 15px;
    vertical-align: middle;
  }
  .searchInput{
    color: black;
    border: none;
    background-color: white;
    border-radius: 13px;
    height: 30px;
    width: 8vw;
    outline: none;
    padding: 2%;
    font-size: 14px;
    text-align: center;
  }
  input.searchInput::placeholder{
    text-align: center;
    
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    /*.sidenav a {font-size: 18px;}*/
  }
 
  .column1_row1_ProductInfo {
    color: black;
    float: left;
    width: 16%;
    text-align: left;
    padding-left: 2%;
  }
  
  .column2_row1_ProductInfo {
    color: black;
    float: left;
    width: 18%;
    text-align: left;
    padding-left: 2%;
  }
  
  .column3_row1_ProductInfo {
    color: black;
    float: left;
    width: 14%;
    text-align: left;
    padding-left: 2%;
  }
  
  .column4_row1_ProductInfo {
    color: black;
    float: left;
    width: 10%;
    text-align: left;
    padding-left: 2%;
  }
  
  .column5_row1_ProductInfo {
    color: black;
    float: left;
    width: 14%;
    text-align: left;
    padding-left: 2%;
  }
  
  .column6_row1_ProductInfo {
    color: black;
    float: left;
    width: 14%;
    text-align: left;
    padding-left: 2%;
  }
  
  .column7_row1_ProductInfo {
    color: black;
    float: left;
    width: 4%;
  }
  

  .row1_ProductInfo:after {
    content: "";
    display: table;
    clear: both;
  }

  .row1_ProductInfo{
    margin-right: 3%;
  }

  .column1_row2_ProductInfo {
    color: white;
    float: left;
    width: 16%;
    text-align: left;
    padding-left: 2%;
    margin-top: 8px;
  }
  
  .column2_row2_ProductInfo {
    color: white;
    float: left;
    width: 18%;
    text-align: left;
    padding-left: 2%;
    margin-top: 8px;
  }
  
  .column3_row2_ProductInfo {
    color: white;
    float: left;
    width: 14%;
    text-align: left;
    padding-left: 2%;
    margin-top: 8px;
  }
  
  .column4_row2_ProductInfo {
    color: white;
    float: left;
    width: 10%;
    text-align: left;
    padding-left: 2%;
    margin-top: 8px;
  }
  
  .column5_row2_ProductInfo {
    color: white;
    float: left;
    width: 14%;
    text-align: left;
    padding-left: 2%;
    margin-top: 8px;
  }
  
  .input_column5_row2_ProductInfo{
    background-color: #e1d0bf;
    border: none;
    height: 25px;
    font-size: 16px;
    width: 95%;
    outline: none;
  }

  .column6_row2_ProductInfo {
    color: white;
    float: left;
    width: 12%;
    text-align: left;
    padding-left: 2%;
    margin-top: 8px;
  }
  
  .column7_row2_ProductInfo {
    color: white;
    float: left;
    width: 3%;
    height: 100%;
  }
  
  .label_row2_ProductInfo{
    color: black;
  }

  .row2_ProductInfo:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .row2_ProductInfo {
    background-color: #97acb4;
    height: 35px;
    margin-right: 3%;
    border-radius: 50px;
    padding-top: 0px;
    margin-bottom: 10px; /* Add this line to create space between rows */
    margin-top: 10px; /* Add or adjust this line for consistent spacing at the top of the first row */
  }

  .dustbin{
    height: 20px;
    margin-top: -15px; 
  }
  .spacedColumn {
    margin-bottom: 10px; /* Adjust the value as needed to control the space between columns */
  }
.label_row2_ProductInfo {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* This adds an ellipsis (...) to indicate text overflow */
}
