body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
    text-align: center;
    margin-top: 60px;
}
form {
    background-color: 'red';
}
.imgcontainer_SignIn {
  text-align: center;
  /* margin-top: 2% ; */
  /* margin-left: 4%; */
  /* margin-top: -8%; */
  /* margin-left: 7%; */
}
img.avatar {
  width: 30%;
}
.container_SignIn{
  padding: 25px;
  align-items: center;
}
.lets_Sign_you_In{
  margin-top: 70;
  margin-left: '40%';
  color: black;
  font-size: 2vw;
  text-decoration: underline 1.3px;
}
input[type=text_SignIn], input[type=password] {
  width: 40%;
  padding: 20px;
  margin: 8px 0;
  background-color: white;
  border: none;
  text-align: center;
  font-size: 1.2vw;
  outline: none;
}
input[type=text_SignIn], input[type=password]::placeholder {
  text-align: center;
  font-size: 1.2vw;
}
.forgot_pswd {
  padding-top: 30px;
  text-decoration:underline;
  color: black;
  font-size: 1.2vw
}
.forgot_pswd_btn_SignIn{
  border: none;
  color: black;
  background-color: white;
  text-decoration: underline;
  width: 20%;
  font-size: 1.2vw
}
.forgot_pswd_btn_SignIn:hover{
  background-color: white;
}
.signIn_btn_SignIn{
  background-color: #61777F;
  color:  white;
  padding: 12px 20px;
  margin: 30px 0;
  border: none;
  cursor: pointer;
  width: 20%;
  border-radius: 50px;
  height: 3vw;
  font-size: 1.2vw;
}
.signIn_btn_SignIn:hover{
  background-color: #61777F;
}
button:hover {
  opacity: 0.5;
}
hr{
  width: 40%;
}

/*Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    background-color: aqua;
  }
}

