.imgcontainer1 {
    text-align: center;
    /* margin-top: 2% ; */
    /* margin-left: 4%; */
    /* margin-top: -8%; */
    margin-left: 8%;
  }
  img.avatar1 {
    width: 30%;
  }
  body{
    font-family: "Lato", sans-serif;
    background-color: white;
  }
  .typeOfKitchen1{
    margin-top: 0%;
    float: left;
    color: #3f2718;
    font-size: 1.3vw;
  }
  .sidenav1 {
    height: 100%;
    width: 9%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: black;
    overflow-x: hidden;
    text-align: center;
    border-radius: 0px 30px 30px 0px;
  }
  
  /*.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #e1d0bf;
    display: block;
  }*/
  
  img.home-icon1{
      height: 30px;
      width: 35px;
      margin-top: 100%;
      
  }
  img.database-icon1{
    opacity: 0.9;
    height: 36px;
    width: 36px;
    margin-top: 20%;
}
  img.cloud-icon1{
      height: 40px;
      width: 38px;
      margin-top: 20%;
  }
  img.settings-icon1{
      height: 33px;
      width: 35px;
      margin-top: 20%;
  
  }
  img.logout-icon1{
      margin-top: 200%;
      height: 30px;
      width: 35px;
  }
  