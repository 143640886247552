.imgcontainer {
    text-align: center;
    /* margin-top: 2% ; */
    /* margin-left: 4%; */
    margin-top: -4%;
  }
  img.avatar {
    width: 30%;
  }
  body {
    font-family: "Lato", sans-serif;
    background-color: #e1d0bf;
  }
  
  .sidenav {
    height: 100%;
    width: 9%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #3f2718;
    overflow-x: hidden;
    text-align: center;
    border-radius: 0px 30px 30px 0px;
  }
  
  /*.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #e1d0bf;
    display: block;
  }*/
  
  img.home-icon{
      opacity: 0.8;
      height: 30px;
      width: 35px;
      margin-top: 100%;
      
  }
  img.database-icon{
    opacity: 0.7;
    height: 36px;
    width: 36px;
    margin-top: 20%;
}
  img.cloud-icon{
      opacity: 0.9;
      height: 40px;
      width: 38px;
      margin-top: 20%;
  }
  img.settings-icon{
      height: 33px;
      width: 35px;
      opacity: 1;
      margin-top: 20%;
  
  }
  img.logout-icon_s{
      opacity: 0.9;
      margin-top: 200%;
      height: 30px;
      width: 35px;
  }
  
  .main_setting {
    margin-top: 2.5%;
    margin-left: 7%; 
    font-size: 18px; /* Increased text to enable scrolling */
    padding: 0px 10px;
    align-self: center;
    
  }
  a
  {
      text-decoration: none;
      color: black;
      line-height: 2.5;
      
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    /*.sidenav a {font-size: 18px;}*/
  }
  .changePassword_btn, .changeUsername_btn, .changeEmail_btn, .addNewCategory_btn{
    background-color: #61777F;
    color:  white;
    padding: 1vw;
    margin: 30px 0;
    border: none;
    cursor: pointer;
    width: 20vw;
    border-radius: 50px;
    font-size: 20px;
  }

  .changePassword_btn:hover, .changeUsername_btn:hover, .changeEmail_btn:hover, .addNewCategory_btn:hover{
    background-color: #61777F;
  }

  button:hover {
    opacity: 0.5;
  }