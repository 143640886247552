

.container_preview {
  max-width: 60%;
  margin: 0 auto;
  padding: 20px;
  margin-left: 21%;
  margin-right: 15%;
  align-items: center;
}

.responsive-div {
  border-radius: 20px;
  margin-left: -8%;
  height: 50vh;
  width: 100%;
  padding: 0px;
  /* background-color: #604c3c; */
  /* border: 1px solid #ccc; */
  text-align: left;
}

@media (min-width: 768px) {
  .responsive-div {
    width: 100%;
  }
}



.imgcontainer {
    text-align: center;
    margin-top: 2% ;
    margin-left: 4%;
  }
  img.avatar {
    width: 30%;
  }
  
  
  .sidenav {
    height: 100%;
    width: 9%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #3f2718;
    overflow-x: hidden;
    text-align: center;
    border-radius: 0px 30px 30px 0px;
  }
  
  img.home-icon{
      opacity: 0.8;
      height: 30px;
      width: 35px;
      margin-top: 100%;
      
  }
  img.database-icon{
    opacity: 0.7;
    height: 36px;
    width: 36px;
    margin-top: 20%;
}
  img.cloud-icon{
      opacity: 0.9;
      height: 40px;
      width: 38px;
      margin-top: 20%;
  }
  img.settings-icon{
      height: 33px;
      width: 35px;
      opacity: 1;
      margin-top: 20%;
  
  }
  img.logout-icon{
      opacity: 0.9;
      margin-top: 200%;
      height: 30px;
      width: 35px;
  }
  
  .main{
    margin-top: 2.5%;
    margin-left: 14%; 
    font-size: 18px; 
    padding: 0px 10px;
    
  }
  a
  {
    text-decoration: none;
    color: #3f2718;
    line-height: 2.5;
      
  }
  .self_btn{
    margin-top: 5%;
    width: 20%;
    height: 60px;
    border-radius: 0.5vw;
    background-color: #61777F;
    color: white;
    font-size: 1.3vw;
    border: none;
    padding: 0.5%;
  }

  .franchise_btn{
    margin-top: 10%;
    width: 20%;
    height: 60px;
    border-radius: 0.5vw;
    background-color: #61777F;
    color: white;
    font-size: 1.3vw;
    border: none;
    padding: 0.5%;
  }

  .self_btn:hover, .franchise_btn:hover{
    background-color: #61777F;
  }
  .input1_of_preview{
    outline: none;
    width: 18.5%;
    border-radius: 0.5vw;
    background-color: white;
    height: 30px;
    font-size: 1.2vw;
    margin-top: 1%;
    border: 2px solid #61777F;
    padding: 0.5%;
  }
  .input2_of_preview{
    border: none;
    outline: none;
    width: 18.5%;
    border-radius: 0.5vw;
    height: 30px;
    font-size: 1.2vw;
    margin-top: 1%;
    border: 2px solid #61777F;
    padding: 0.5%;
  }
  
/*
.brownBox{
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  background-color: #604c3c;
  height: 500px;
  width: 700px;
  border-radius: 20px;
  margin-left: 20%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    
  }
 
}

.self_container{
  margin-top: 10%;
}

.self_btn{
  height: 60px;
  width: 24%;
  background-color: #3f2718;
  border-radius: 10px;
}
.self_btn:hover{
  background-color:#3f2718 ;
}

.self_previewLabel{
  color: #e1d0bf;
}

.franchise_container{
  margin-top: 10%;
}
.franchise_btn{
  height: 60px;
  width: 24%;
  background-color: #3f2718;
  border-radius: 10px;
}
.franchise_btn:hover{
  background-color: #3f2718;
}
.franchise_previewLabel{
  color: #e1d0bf;
}
*/
.complete_btn{
  margin-top: 5%;
  margin-bottom: 10%;
  background-color: #61777F;
  color: white;
  border-radius: 50px;
  /* height: 30px;
  width: 80px; */
  font-size: 1.3vw;
  padding: 7%;
  }
  .complete_btn:hover{
  background-color: #61777F;
  }
  
  
  
  .complete_btn_pos{
  
  float: right;
  margin-top: 8%;
  /* margin-bottom: 20px; */
  margin-right: 1%;
  }

