  body {
    font-family: Arial, sans-serif;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #758D93; /* Pink color for table header */
  }
  .input_quotation{
    border:none;
    outline:none;
  }
  .title_quotation{
    color:white;
  }